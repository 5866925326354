// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Afm from "../app-core/Afm.bs.js";
import * as React from "react";
import * as NitroAd from "./NitroAd.bs.js";
import * as VideoAd from "./VideoAd.bs.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";

function AdHor(Props) {
  var narrowOpt = Props.narrow;
  var narrow = narrowOpt !== undefined ? narrowOpt : false;
  Afm.use(undefined);
  var screenSize = ScreenAtom.useNoSSR(undefined);
  var sizes = React.useMemo((function () {
          if (screenSize >= 2) {
            if (screenSize >= 4) {
              return [
                      [
                        320,
                        50
                      ],
                      [
                        320,
                        100
                      ]
                    ];
            } else {
              return [[
                        728,
                        90
                      ]];
            }
          } else if (narrow) {
            return [
                    [
                      728,
                      90
                    ],
                    [
                      970,
                      90
                    ]
                  ];
          } else {
            return [
                    [
                      970,
                      250
                    ],
                    [
                      728,
                      90
                    ],
                    [
                      970,
                      90
                    ]
                  ];
          }
        }), [screenSize]);
  return React.createElement("div", {
              className: "min-h-[32px] lg:min-h-[40px]"
            }, React.createElement("div", {
                  className: "afm-wrap flex items-center justify-center mt-4 mb-6 md:mt-6 md:mb-8 xl:mx-[-150px] min-h-[100px] md:min-h-[90px] " + (
                    narrow ? "" : "xl:min-h-[250px]"
                  )
                }, React.createElement("div", {
                      className: "min-w-[320px] md:min-w-[728px] xl:min-w-[970px]"
                    }, React.createElement(NitroAd.make, {
                          id: "na-top-hor" + (
                            narrow ? "-narrow" : ""
                          ),
                          sizes: sizes
                        })), narrow ? null : React.createElement("div", {
                        className: "hidden xl:block xl:ml-8 xl:w-[444px] xl:h-[250px]"
                      }, React.createElement(VideoAd.Np.make, {
                            screenSize: screenSize
                          }))));
}

var make = AdHor;

export {
  make ,
  
}
/* Afm Not a pure module */
